html {
  margin: 0;
  padding: none;
  padding: env(safe-area-inset)
}

body {
  font-family: "Inter Tight", sans-serif;
  font-weight: 300;
  margin: 0;
  padding: none;
}

h2, h3, h4, h5, h6, p {
  margin: 10px 0px;
}

h2, h3 {
  font-weight: 500;
  margin-top: 20px;
  margin-bottom: 20px;

}

p {
  color: grey;
}

.landingPageTopOfFold {
  margin-bottom: 25px;
  text-align: center;
  padding: 0px 20px;
  background-color: #166FFF;
  height: 100vh;
  min-height: 650px;
  position: relative;
  overflow: hidden
}
.landingPageTopOfFold h1 {
  padding-top: 20px;
  margin-top: 0px;
  text-align: center;
  color: white;
}

.MuiBottomNavigationAction-label.Mui-selected {
  font-size: 0.75rem;
}

.oregano-regular-italic {
  font-family: "Oregano", cursive;
  font-weight: 400;
  font-style: italic;
}

.oregano-regular {
  font-family: "Oregano", cursive;
  font-weight: 400;
  font-style: "normal"
}

.inter-tight-heavy {
  font-family: "Inter Tight", sans-serif;
  font-optical-sizing: auto;
  font-weight: 600;
  font-style: normal;
}

.clickablePaper:hover {
  cursor: pointer;
}

code {
  font-family: 'Roboto', sans-serif;
}

.addRecipeInput {
  margin-top: 10px;
}

.recipeCard:hover {
  cursor: pointer;
}

.addRecipeLabel {
  color: black;
  font-size: 18px;
  font-weight: 500;
  margin-bottom: 10px

}

.requiredInput {
  color: red;
}

.adornmentBlue .MuiTypography-root {
  color: #166FFF
}

.prepCookContainer {
  border: 1px solid grey;
  margin-top: 20px;
  padding: 10px;
  border-radius: 4px;
  align-items: center;
}

.MuiIcon-root {
  position: "fixed";
  bottom: "20px";
  right: "20px"; 
  z-index: "100";
  background-color: "#166FFF";
  padding: "10px";
  color: "white";
  border-radius: "50%";
  font-size: "30px";
  box-shadow: "3px 3px 10px grey"
}

.MuiFilledInput-input {
  border-radius: "10px";
}
input {
  margin: 5px 0px;
  border: 1px solid grey;
  font-size: 15px;
  padding: 5px;
  border-radius: 3px;
  box-sizing:border-box;
  width: 100%;
}

/* label {
  display: block;
} */

.navbar a{
  text-decoration: none;
  color: black;
}

.MuiButton-contained {
border-radius: 18px;
padding: 10px 25px;
border-radius: 22px;
box-shadow: "none";
}

.MuiButton-contained:active {
  border-radius: 18px;
  padding: 10px 25px;
  border-radius: 22px;
  }

  .MuiButton-contained:hover {
    background-color: transparent;
    color: #166FFF;
  }

  .landingPageImage {
    position: absolute;
    bottom: "0px";
    left: 50%;
    transform: translate(-50%, 0%);
    width: 420px;
    bottom: -60px;
  }

  .addIcon {
    -webkit-box-shadow: "3px 3px 5px grey";
    box-shadow: "3px 3px 5px grey";
  }

  @media(min-width: 500px) {
    .landingPageImage {
      position: absolute;
      bottom: "0px";
      left: 50%;
      transform: translate(-50%, 0%);
      width: 500px;
      bottom: -60px;
    }
  }

.signUpInBackground {
  overflow: hidden;
  height: 100vh;
}

.purpleBorder {
  border: "1px blue solid"
}

.MuiTab-root {
  max-width: 1000px
}

.profilePicture {
  height: 100px;
  width: 100px;
  border-radius: 50%;
  margin: 20px auto;
  display: block;

}

.signUpIn {
  margin: 15px auto;
  width: 75%;
  margin: 10px auto 0px auto;
  padding: 10px;
  background-color: rgba(128,128,128, 0.7);
  box-sizing: content-box;
}

.signUpIn label {
  font-family: 'Pangolin';
  font-weight: 600;
  font-size: 25px;
  margin: 0px 5px;
}

.signUpIn input {
  margin: 0px;
  font-size: 25px;
}

.signUpIn button {
  margin-top: 20px;
}

/* .landingPageSignIn {
  background-color: orange;
  display: inline-block;
  height: 100vh;
  width:40%;
  padding: 10px;
} */
.landingPageSignUp {
  display: block;
  background-color: white;
  border-radius: 3px;
  border: 2px solid lightgray;
  box-shadow: 4px 4px 10px darkgray;
  width: 60%;
  padding: 20px;
  margin: auto;
  box-sizing: border-box;
}

form {
  box-sizing: border-box;
}

select {
  font-size: 25px;
}

.recipeLandingPage {
border: 2px solid lightgray;
width: 90%;
margin: auto;
box-sizing: border-box;
}

.recipeImage {
  display: inline-block;
  width: 30%;
  vertical-align: top;
  object-fit: fill;
}

.recipeImage img {
  height: 75px;
  vertical-align: top;
  text-align: left;
}

.recipeDetail {
  display: inline-block;
  width: 60%;
  height: 100%;
  text-align: left;
  box-sizing: border-box;
  padding-left: 25px;
}

.recipeDetail img {
  height: 30px;
  width: 30px;
  border-radius: 50%;
  display: inline-block;
}

.recipeDetail h3 {
  display: inline-block;
  margin: 5px;
}
.recipeDetail p {
  display: inline-block;
  margin-left: 10px;
  vertical-align: top ;
  margin-bottom:5px;
}

.MuiListItemButton-root:hover {
  background-color: transparent
}

.headerText {
  text-align: center;
}

.recipePicture {
  height: 95px;
  width: 95px;

}

@media screen and (min-width: 900px) {
  h3 {
    font-size: 25px;
  }
  .headerText {
    text-align: left;
  }
  .profilePicture {
    height: 200px;
    width: 200px;
  }
  .profileInfo p {
    font-size: 25px
  }
  .recipePicture {
    height: 200px;
    width: 200px;
  }
  .landingPageTopOfFold h1 {
    font-size: 40px;
  }
  .landingPageTopOfFold p {
    font-size: 18px;
  }
}